import smoothscroll from 'smoothscroll-polyfill';
import iframeResizer from 'iframe-resizer/js/iframeResizer';
import IFRAME_ENDPOINTS from './const/index';

const DEFAULT_TOKEN_IFRAME_ID = 'webModuleHolderTokenIframe';
const DEFAULT_WEBMODULE_IFRAME_ID = 'webModuleHolderIframe';

smoothscroll.polyfill();

const buildTokenUrl = (
    { env = 'production', version = 'latest-production', campaign = '', organization = '' },
    webModuleToken,
) => {
    let imageUrl = `${IFRAME_ENDPOINTS[env]}/`;

    if (version && env === 'production') {
        imageUrl += `${version}/`;
    }
    // webModuleToken is a temp token for requesting actual jwt auth token
    // eslint-disable-next-line
    imageUrl += `index.html#/setToken?campaign=${campaign}&organization=${organization}&web-module-token=${webModuleToken}`;

    return imageUrl;
};

const getCoords = elem => {
    // crossbrowser version
    const box = elem.getBoundingClientRect();

    const { body, documentElement } = document;

    const scrollTop = window.pageYOffset || documentElement.scrollTop || body.scrollTop;
    const scrollLeft = window.pageXOffset || documentElement.scrollLeft || body.scrollLeft;

    const clientTop = documentElement.clientTop || body.clientTop || 0;
    const clientLeft = documentElement.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
};

const buildIframeUrl = ({
    env = 'production',
    version = 'latest-production',
    syncUrls = true,
    webModuleId = '',
    campaign = '',
    organization = '',
}) => {
    let iframeUrl = `${IFRAME_ENDPOINTS[env]}/`;

    if (version && env === 'production') {
        iframeUrl += `${version}/`;
    }

    // eslint-disable-next-line
    iframeUrl += `index.html#/?web-module-id=${webModuleId}&campaign=${campaign}&organization=${organization}`;

    if (syncUrls) {
        const webModulePathname = window.location.hash.substr(1);

        if (webModulePathname) {
            iframeUrl += `&web-module-pathname=${encodeURIComponent(webModulePathname)}`;
        }
    }

    return iframeUrl;
};

const getElementHolder = holderCssSelector => {
    let elementHolder;

    if (holderCssSelector) {
        elementHolder = document.querySelector(holderCssSelector);
    }

    if (!elementHolder) {
        elementHolder = document.body;
    }

    return elementHolder;
};

const webModuleLoader = {
    initToken(props) {
        const { holderCssSelector = '#webModuleHolder', webModuleOAuthToken, ...urlProps } = props;

        if (webModuleOAuthToken) {
            const iframe = document.createElement('iframe');
            const iframeUrl = buildTokenUrl(urlProps, webModuleOAuthToken);

            iframe.setAttribute('style', 'display:none;');
            iframe.setAttribute('id', DEFAULT_TOKEN_IFRAME_ID);

            const iframeHolder = getElementHolder(holderCssSelector);

            iframeHolder.appendChild(iframe);

            return new Promise(resolve => {
                const resizerOptions = {
                    checkOrigin: false,
                    messageCallback: ({ message }) => {
                        const { type: messageType } = message;

                        if (messageType && messageType === 'token regenerated') {
                            resolve();
                        }
                    },
                };

                iframeResizer(resizerOptions, iframe);

                iframe.setAttribute('src', iframeUrl);
            });
        }

        return Promise.reject(new Error('Empty or missing jwt token!'));
    },
    initIframe(props) {
        const { holderCssSelector = '#webModuleHolder', resizerOptions = {}, scrollOffset = 200, ...urlProps } = props;

        if (!urlProps.webModuleId) {
            return;
        }

        const iframe = document.createElement('iframe');
        const iframeUrl = buildIframeUrl(urlProps);

        iframe.setAttribute('style', 'width:1px;min-width:100%;border:0 none;outline:0 none;margin:0;padding:0;');
        iframe.setAttribute('id', DEFAULT_WEBMODULE_IFRAME_ID);
        iframe.setAttribute('scrolling', 'no');
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allowfullscreen', 'allowfullscreen');

        const iframeHolder = getElementHolder(holderCssSelector);

        iframeHolder.appendChild(iframe);

        const { syncUrls = true } = urlProps;

        if (syncUrls) {
            resizerOptions.checkOrigin = false;
            resizerOptions.messageCallback = ({ message }) => {
                const { type: messageType, payload } = message;

                if (messageType && messageType === 'route changed' && payload.path) {
                    window.scrollTo({
                        top: Math.max(0, getCoords(iframeHolder).top - scrollOffset),
                        left: 0,
                        behavior: 'smooth',
                    });
                    window.history.replaceState(undefined, undefined, `#${payload.path}`);
                }
            };
        }

        iframeResizer(resizerOptions, iframe);

        iframe.setAttribute('src', iframeUrl);
    },
    init(props) {
        this.initToken(props)
            .then(() => {
                const tokenInitIframe = document.querySelector(`#${DEFAULT_TOKEN_IFRAME_ID}`);

                if (tokenInitIframe) {
                    tokenInitIframe.iFrameResizer.removeListeners();
                    tokenInitIframe.iFrameResizer.close();
                }

                this.initIframe(props);
            })
            .catch(() => {
                const tokenInitIframe = document.querySelector(`#${DEFAULT_TOKEN_IFRAME_ID}`);

                if (tokenInitIframe) {
                    tokenInitIframe.iFrameResizer.removeListeners();
                    tokenInitIframe.iFrameResizer.close();
                }

                this.initIframe(props);
            });
    },
};

window.webModuleLoader = webModuleLoader;

export default webModuleLoader;
